import axios from "axios";
import qs from "qs";
import {
    Message
} from 'element-ui';
axios.defaults.timeout = 15000; // 超时时间
import url from "./url";
axios.defaults.baseURL = url.url;
import store from '../store';
import router from "../router"

const type = "web";

//http request 拦截器
axios.interceptors.request.use(
    (config) => {
        let data = config.data || {};

        if (config.method == "get") {
            data = config.params || {};
        } else {
            data = config.data || {};
        }
        if (localStorage["token"]) {
            data.access_token = localStorage["token"] || "";
        }
        config.headers = {
            "Content-Type": "application/x-www-form-urlencoded",
        };
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

//响应拦截器即异常处理
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (err) => {
        if (err && err.response) {
            switch (err.response.code) {
                case 400:
                    console.log("错误请求");
                    break;
                case 401:
                    LogOut()
                    console.log("未授权，请重新登录");

                    break;
                case 403:
                    console.log("拒绝访问");
                    break;
                case 404:
                    console.log("请求错误,未找到该资源");
                    break;
                case 405:
                    console.log("请求方法未允许");
                    break;
                case 408:
                    console.log("请求超时");
                    break;
                case 500:
                    console.log("服务器端出错");
                    break;
                case 501:
                    console.log("网络未实现");
                    break;
                case 502:
                    console.log("网络错误");
                    break;
                case 503:
                    console.log("服务不可用");
                    break;
                case 504:
                    console.log("网络超时");
                    break;
                case 505:
                    console.log("http版本不支持该请求");
                    break;
                default:
                    console.log(`连接错误${err.response.status}`);
            }
        } else {
            console.log("连接到服务器失败");
        }
        return Promise.resolve(err.response);
    }
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params, next, message = 0) {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params,
            })
            .then((response) => {
                if (response.status == 200) {
                    const res = response
                    if (res.data.code == 200) {
                        resolve(res.data);
                    } else {
                        if (next) {
                            if (!message) {
                                Message.error(res.data.msg);
                            }
                            reject(res.data);
                        } else {
                            reject(res.data);
                        }
                    }
                } else {
                    Message.error(response.data.msg);
                    reject(res.data);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @param config
 * @returns {Promise}
 */

export function post(url, data = {}, config = {}, next) {
    return new Promise((resolve, reject) => {
        axios.post(url, data, config).then(
            (response) => {
                if (response.status == 200) {
                    const res = response
                    if (res.data.code == 1 || res.data.code == 200) {
                        resolve(res.data);
                    } else {
                        if (next) {
                            reject(res.data);
                        } else {
                            reject(res.data);
                        }
                    }
                } else {
                    reject(res.data);
                }
            },
            (err) => {
                reject(err);
            }
        );
    });
}


export function upfile(url, data = {}, config = {}, next) {
    return new Promise((resolve, reject) => {
        axios.post(url, data, config).then(
            (response) => {
                if (response.status == 200) {
                    const res = response
                    if (res.data.code == 200) {
                        resolve(res.data);
                    } else {
                        if (next) {
                            reject(res.data);
                        } else {
                                Message.error(res.data.msg);
                            reject(res.data);
                        }
                    }
                } else {
                    if (response.status == 401) {
                        router.push("/login");
                    }
                    Message.error(response.data.msg || '连接失败');
                    reject(res.data);
                }
            },
            (err) => {
                reject(err);
            }
        );
    });
}


/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

/**
 * 封装delete方法
 * @param url
 * @param data
 * @returns {Promise}
 * delete 为保留字，因此删除方法使用大写字母
 */

export function DELETE(url, params, next, message = 0) {
    return new Promise((resolve, reject) => {
        axios
            .delete(url, {
                params: params,
            })
            .then((response) => {
                if (response.status == 200) {
                    const res = response
                    if (res.data.code == 200) {
                        resolve(res.data);
                    } else {
                        if (next) {
                            if (!message) {
                                Message.error(res.data.msg);
                            }
                            reject(res.data);
                        } else {
                                Message.error(res.data.msg);
                            reject(res.data);
                        }
                    }
                } else {
                    Message.error(response.data.msg);
                    reject(res.data);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

// 退出登录
function LogOut() {
    
}





const uploadObject = axios.create({
    headers: {
        "Content-Type": "multipart/form-data; charset=utf-8",
    },
    method: "post",
});

export function upload(obj) {
    return uploadObject(obj);
}